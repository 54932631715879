<template>
  <a-layout style="height: 100%;">
    <a-layout-header>
      <div class="topNav">
        <img src="../../assets/images/home/Frame.svg" alt="">
        <div class="top-btn">
          <a-menu mode="horizontal" :default-selected-keys="['1']" v-model:selectedKeys="selectedKeys"
            v-model:openKeys="openKeys">
            <a-menu-item key="homepage">
              <router-link to="/homepage" class="link">Workbench</router-link>
            </a-menu-item>
            <a-sub-menu key="about">
              <template #title>About Us</template>
              <a-menu-item key="aboutus">
                <router-link to="/about/aboutus" class="menu-link">About Us</router-link>
              </a-menu-item>
              <a-menu-item key="warehousing">
                <router-link to="/about/warehousing" class="menu-link">Logistics Warehousing</router-link>
              </a-menu-item>
              <a-menu-item key="vehicles">
                <router-link to="/about/vehicles" class="menu-link">Vehicles</router-link>
              </a-menu-item>
              <a-menu-item key="servicearea">
                <router-link to="/about/servicearea" class="menu-link">Service Area</router-link>
              </a-menu-item>
            </a-sub-menu>
            <a-menu-item key="contact">
              <router-link to="/contact" class="link">Contact Us</router-link>
            </a-menu-item>
            <a-sub-menu key="support">
              <template #title>Support Center</template>
              <a-menu-item key="buyerfaq">
                <router-link to="/support/buyerfaq" class="menu-link">Recipient FAQs</router-link>
              </a-menu-item>
              <a-menu-item key="retailerfaq">
                <router-link to="/support/retailerfaq" class="menu-link">Retailer FAQs</router-link>
              </a-menu-item>
            </a-sub-menu>
            <a-sub-menu key="resources">
              <template #title>Resources</template>
              <a-menu-item key="privacypolicy">
                <router-link to="/resources/privacypolicy" class="menu-link">Privacy Policy</router-link>
              </a-menu-item>
              <a-menu-item key="termsconditions">
                <router-link to="/resources/termsconditions" class="menu-link">Terms & Conditions
                </router-link>
              </a-menu-item>
              <a-menu-item key="feessurcharge">
                <router-link to="/resources/feessurcharge" class="menu-link">Fees & Surcharges
                </router-link>
              </a-menu-item>
              <a-menu-item key="tailoredsolutions">
                <router-link to="/resources/tailoredsolutions" class="menu-link">Tailored Delivery Solutions
                </router-link>
              </a-menu-item>
            </a-sub-menu>
            <a-sub-menu key="knowledge">
              <template #title>Knowledge Center</template>
              <a-menu-item key="blog">
                <router-link to="/knowledge/blog" class="menu-link">Blog
                </router-link>
              </a-menu-item>
            </a-sub-menu>
            <a-sub-menu key="driver">
              <template #title>Drivers</template>
              <a-menu-item key="qualifications">
                <router-link to="/driver/qualifications" class="menu-link">Driver Qualifications
                </router-link>
              </a-menu-item>
              <a-menu-item key="driverfaq">
                <router-link to="/driver/driverfaq" class="menu-link">Driver FAQ
                </router-link>
              </a-menu-item>
            </a-sub-menu>
          </a-menu>
          <a-space size="large">
            <a-button shape="round" @click="$router.push('/login')">Log In</a-button>
            <a-button type="primary" shape="round" @click="$router.push('/register')">Sign up</a-button>
          </a-space>
        </div>
      </div>
    </a-layout-header>
    <a-layout-content>
      <router-view></router-view>
      <a-alert v-show="!isCookie" class="privacy" closable @close="closeCookieAlert">
        We use cookies to ensure that we give you the best experience on our website.To accept cookies, click "Accept All"
        and continue browsing as normal, or go to Privacy Policy for more information.<a-link
          @click="$router.push('/resources/privacypolicy')">Privacy
          Policy</a-link>
        <template #action>
          <a-button size="small" type="primary" @click="isAcceptCookie">Accept All</a-button>
        </template>
      </a-alert>
    </a-layout-content>
    <a-layout-footer>
      <div class="footer-cont">
        <div class="left-cont">
          <img src="../../assets/images/home/homeBottom.svg" alt="">
          <div class="left-btn">
            <span class="l" @click="$router.push('/resources/privacypolicy')">Privacy Policy</span>
            <span @click="$router.push('/resources/termsconditions')">Terms & Conditions </span>
            <div class="copy">Copyright © American Delivery Service 2022</div>
          </div>
        </div>
        <div class="right-cont">
          <div class="cont mr">
            <div @click="$router.push('/login')">Client Login</div>
            <div @click="$router.push('/register')">Sign Up</div>
            <div @click="$router.push('/homepage')">Workbench</div>
            <div @click="$router.push('/about/aboutus')">About Us</div>
            <div @click="$router.push('/about/vehicles')">Vehicles</div>
            <div @click="$router.push('/about/warehousing')">Logistics Warehousing</div>
            <div @click="$router.push('/about/servicearea')">Service Area</div>
            <div @click="$router.push('/knowledge/blog')">Blog</div>
          </div>
          <div class="cont">
            <div @click="$router.push('/homepage')">Track Your Package</div>
            <div @click="$router.push('/homepage')">Make A Quotation</div>
            <div @click="$router.push('/contact')">Contact Us</div>
            <div @click="$router.push('/support/buyerfaq')">Recipient FAQs</div>
            <div @click="$router.push('/support/retailerfaq')">Retailer FAQs</div>
            <div @click="$router.push('/resources/feessurcharge')">Fees & Surcharges</div>
            <div @click="$router.push('/resources/tailoredsolutions')">Tailored Delivery Solutions</div>
            <div @click="$router.push('/driver/qualifications')">Driver Qualifications</div>
            <div @click="$router.push('/driver/driverfaq')">Driver FAQ</div>
          </div>
        </div>
      </div>
    </a-layout-footer>
    <a-button shape="circle" size="large" id="backTop">
      <icon-to-top />
    </a-button>
  </a-layout>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { IconToTop } from '@arco-design/web-vue/es/icon'
// 选中的key
const selectedKeys = ref([''])
// 展开的选项
const openKeys = ref([''])
const route = useRouter()
watch(() => route.currentRoute.value.path, (newValue) => {
  const patharr = newValue.split('/')
  patharr.splice(0, 1)
  if (patharr.length === 1) {
    selectedKeys.value = patharr.slice(0, 1)
  } else {
    openKeys.value = patharr.slice(0, 1)
    selectedKeys.value = [patharr[patharr.length - 1]]
  }
}, { immediate: true })

// 是否同意cookie
const isCookie = ref(false)
// 获取cookie
const getCookie = (key) => {
  var strCookie = document.cookie;
  // 将多cookie切割为多个名/值对
  var arrCookie = strCookie.split("; ");
  for (var i = 0; i < arrCookie.length; i++) {
    var tmp = arrCookie[i].split('=');
    if (tmp[0] === key) {
      return tmp[1];
    }
  }
}
isCookie.value = getCookie('ads_cookie_notice_accepted')
// console.log(isCookie.value)
// 同意cookie
const isAcceptCookie = () => {
  isCookie.value = true
  document.cookie = "ads_cookie_notice_accepted=" + isCookie.value
}
const closeCookieAlert = () => {
  isCookie.value = true
  document.cookie = "ads_cookie_notice_accepted=" + isCookie.value
}
// 返回顶部
onMounted(() => {
  let goTop = document.getElementById('backTop')
  // console.log(goTop)
  window.onscroll = function () {
    // 让谁进行滚动 body html（有的浏览器获取不到body或html），所以做一个兼容的方式
    // scrollTop据顶部的滑动的距离
    let res = document.body.scrollTop || document.documentElement.scrollTop;
    if (res >= 400) { // 当大于400px，按钮出现
      goTop.style.display = 'block'
    } else {
      goTop.style.display = 'none'
    }
  }
  goTop.onclick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
})
</script>

<style lang="less" scoped>
:deep(.arco-menu-overflow-wrap) {
  display: flex;
  justify-content: flex-end;
}

:deep(.arco-menu-selected-label) {
  bottom: -7px;
}

:deep(.arco-menu-horizontal .arco-menu-inner) {
  padding: 14px 48px 14px 0;
}

:deep(.arco-menu-horizontal .arco-menu-pop:not(:first-child)) {
  margin-left: 0;
}

.menu-link {
  text-decoration: none;
  color: var(--color-text-1);
  display: inline-block;
  // width: 180px;
  width: 100%;
}

.link {
  width: 100%;
  display: inline-block;
}

.topNav {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

  img {
    padding-left: 82px;
  }

  .top-btn {
    padding-right: 24px;
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  :deep(.arco-tabs-nav::before) {
    background-color: transparent;
  }
}

.arco-layout-content {
  min-width: 1300px;
}

#backTop {
  width: 40px;
  height: 40px;
  font-size: 14px;
  position: fixed;
  background-color: rgb(var(--primary-1));
  right: 50px;
  bottom: 80px;
  z-index: 999;
  display: none;
}

.privacy {
  position: fixed;
  bottom: 20px;
  left: 0;
  z-index: 999999999;
}

:deep(.arco-alert) {
  padding: 20px;
}

.arco-layout-footer {
  background: rgb(var(--primary-6));
  min-width: 1300px;

  .footer-cont {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    position: relative;

    .left-cont {
      padding-top: 50px;

      img {
        width: 200px;
        padding-bottom: 265px;
      }

      .left-btn {
        font-weight: 400;
        font-size: 16px;
        color: rgb(var(--primary-1));
        line-height: 24px;

        .l {
          padding-right: 50px;
        }

        .copy {
          padding-top: 20px;
          font-weight: 400;
          font-size: 14px;
          color: var(--color-text-4);
          padding-bottom: 10px;
        }

        span {
          cursor: pointer;

          &:hover {
            color: #D9D9D9;
          }
        }
      }
    }

    .right-cont {
      display: flex;
      padding-top: 70px;
      padding-bottom: 40px;

      .cont {
        font-weight: 400;
        font-size: 18px;
        color: rgb(var(--primary-1));

        div {
          padding-bottom: 20px;
          cursor: pointer;

          &:hover {
            color: #D9D9D9;
          }
        }
      }

      .mr {
        margin-right: 100px;
      }
    }
  }
}

:deep(.logs .arco-timeline .arco-timeline-item:first-child .arco-timeline-item-content) {
  font-weight: bold !important;

  .arco-typography {
    font-weight: normal;
  }
}
</style>
